import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN_US from "./locales/en-us.json" 
import PT_BR from "./locales/pt-br.json" 
import FR_FR from "./locales/fr-fr.json" 

const resources = {
    'pt': PT_BR,
    'en': EN_US,
    'fr': FR_FR,
}

i18n.use(initReactI18next).init({
    resources,
    lng: (typeof window !== `undefined`)?navigator?.language : '',
    fallbackLng: "pt"
})



export default i18n;