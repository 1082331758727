import { Link } from "gatsby"
import React, { Children, ReactNode } from "react"
import useMediaQuery from "../../hooks/useMediaQuery";

interface HeroTitleProps{
    children?: string,
}

const HeroTitle = (props: HeroTitleProps): JSX.Element => {
  const isMobile = useMediaQuery("sm");
  const getRand = ()=>{return Math.random()*20}
  return (
    
    <div className="flex gap-4">
    {
      isMobile?
      <h1 className="text-5xl text-red-500 font-bold font-montserrat">Rafael Pereira.</h1>:
      props.children?.split(' ').map((word, i)=>{
        return <div key={i} className="flex">
          {
            word.split('').map(
              (letter, j)=> <h1 key={j} style={{top: 0, left: 0}} className="relative text-5xl text-slate-800 font-bold font-montserrat">{letter}</h1>
            )
          }
        </div> 
        
      })
    }

    </div>
  )
}

export default HeroTitle
