import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

interface FooterProps{
  children?: JSX.Element[];
  className?: string;
}

const Footer = (props: FooterProps): JSX.Element => {
  const { t } = useTranslation();
  
  return (
      <footer className="w-full h-[30vh] bg-stone-900 text-white px-5 py-3 flex justify-center items-center gap-20 font-montserrat">
          <p>{t("Designed by")}</p>
          <button onClick={()=>navigator.clipboard.writeText("rafaelpereira.fullstackdev@gmail.com")} className="bg-stone-600 p-8 rounded-md">{t("Copy email")}</button >
      </footer>
  )
}



export default Footer;