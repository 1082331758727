import React, {createContext, useRef, useState} from 'react'
import { Bodies, Engine, World } from 'matter-js' 
export const MyContext = createContext({});

type vector2 ={
    x: number,
    y: number
}

export const PhysicsContextProvider = (props: {children: any}) => {

    const engine = useRef(Engine.create())
    const world = engine.current.world;
    const bodies = useRef(Bodies)

    function createbox(position: vector2, size: vector2, angle: number, isStatic = false){
        const box = Bodies.rectangle(position.x, position.y, size.x, size.y, {
            angle: angle,
            isStatic: isStatic
        })
        World.add(world, box)
    }

    function _getprovidervalue(){
        return {
            engine: engine,
            world: world,
        }
    }
    return (
        <MyContext.Provider value={_getprovidervalue()}>
            {props.children}
        </MyContext.Provider>
    )
}