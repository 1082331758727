import { useState, useEffect } from "react";

const BreakPoints = {
  'sm': '(max-width: 640px)',
  'md': '(max-width: 768px)',
  'lg': '(max-width: 1024px)',
  'xl': '(max-width: 1280px)',
}

const useMediaQuery = (query: 'sm'|'md'|'lg'|'xl') => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if(typeof window !== `undefined`){
      const media = window.matchMedia(BreakPoints[query]);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }
  }, [matches, query]);

  return matches;
}

export default useMediaQuery;