import { Link } from "gatsby"
import * as React from "react"

interface ListSelectProps{
    children: JSX.Element[],
    className?: string,
    onSelect: (s: string)=> any,
}

const ListSelect = (props: ListSelectProps): JSX.Element => {
    const getChildKey = (child: JSX.Element) => {return child.key?.toString() || ""};
    return (
        <div className={props.className}>
            {
                props.children.map( (child, i) => {
                    return <div key={i} onClick={() => props.onSelect(getChildKey(child))}>{child}</div>
                })
            }
        </div>
    )
}

export default ListSelect
