import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next";
import AnimatedScroll from "../atoms/animatedScroll";
import HeroTitle from "../atoms/heroTitle";

interface HeroProps{
    children?: ReactNode;
  }
const Hero = (props: HeroProps)=>{
    const { t } = useTranslation();
    return (
        <main className="h-screen w-full flex items-center justify-center select-none">
            <div className="w-3/5 flex items-center justify-between">
                <div className="flex flex-col">
                    <h3 className="text-2xl font-ubuntu">{t('Full-stack developer')}</h3>
                    {/* className="text-5xl font-bold font-montserrat" */}
                    <HeroTitle>Rafael Pereira.</HeroTitle>
                </div>
                {props.children}
            </div>
            <AnimatedScroll vanish={()=>{
                return (typeof window !== `undefined`) ?
                    window.scrollY > 50:
                    false
                }} className="w-10 min-w-2 h-10 absolute bottom-7"/>
        </main>
    )
}

export default Hero