import React, { useEffect, useRef, useState } from "react"
import styled, { keyframes } from "styled-components";
import LanguageSelect from "../molecules/languageSelect"

interface NavBarProps{
    children?: JSX.Element[];
  }
const NavBar = (props: NavBarProps)=>{
    const [visible, setVisible] = useState(true)
    const [lastScrollY, _setLastScrollY] = useState(typeof window !== `undefined`? window.scrollY : 0 )
    const lastScrollYRef = useRef(lastScrollY)
    
    const setLastScrollY = (x:any) => {
        lastScrollYRef.current = x;
        _setLastScrollY(x);
    }

    const onScroll = ()=>{
        if(typeof window !== `undefined`){
            (window.scrollY > lastScrollYRef.current && window.scrollY != 0)?
                setVisible(false):
                setVisible(true)
            setLastScrollY(window.scrollY);
        }
    }
    useEffect(() => {
        if(typeof window !== `undefined`){
            window.addEventListener('scroll', onScroll)

            return () => { window.removeEventListener('scroll', onScroll) }
        }
    }, [])
    
    return (
        <NavBarSC className="fixed w-full py-5 px-10 flex flex-row-reverse items-center" animation={visible ? visibleAnimation2: fadeAnimation2}>
            <LanguageSelect/>
        </NavBarSC>
        // <>
        // { visible &&
        //     <nav className="fixed w-full py-5 px-10 flex flex-row-reverse items-center">
        //         <LanguageSelect/>
        //         {/* <NavLinksHorizontalList className="hidden sm:flex mr-10">
        //             <NavLink to="/404">Projetos</NavLink>
        //         </NavLinksHorizontalList> */}
        //     </nav>

        // }
        // </>
    )
}

const fadeAnimation2 = keyframes`
    50%{
        opacity: 100%;
    }
    100% { 
        opacity: 0;
        transform: translate(0,-100%);
    }
`

const visibleAnimation2 = keyframes`
    0% { 
    transform: translate(0,-100%);
    }
    100% { 
    transform: translate(0,0%);
    }
`

const NavBarSC  = styled.nav<{animation?: any}>`
  animation-name: ${props => props.animation};
  animation-duration: .15s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: ${props => {return props.animation == visibleAnimation2? "infinity": "none"}};
`

export default NavBar