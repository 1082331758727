import { Link } from "gatsby"
import React, { Children, ReactNode, useEffect, useState } from "react"
import styled, { keyframes, ThemedStyledFunction } from "styled-components"

interface AnimatedScrollProps{
    children?: ReactNode,
    className?: string,
    vanish?: ()=> boolean
}

const AnimatedScroll = (props: AnimatedScrollProps): JSX.Element => {
    const [visible, setVisible] = useState(!props.vanish || !props.vanish())
    const onScroll = ()=>{
        (!props.vanish || !props.vanish())?
            setVisible(true):
            setVisible(false)
    }

    useEffect(() => {
        if(typeof window !== `undefined`){
          window.addEventListener('scroll', onScroll)
  
          return () => { window.removeEventListener('scroll', onScroll) }
        }
    
    }, [])
    
    return (
        <>
        {
            <Visible 
                animation={visible ? visibleAnimation: fadeAnimation} 
                className={props.className} src="static/MouseScroll.svg"
            />
        }
        </>
    )
}

const fadeAnimation = keyframes`
  100% { 
    opacity: 0;
    transform: translate(0,100%);
  }
`

const visibleAnimation = keyframes`
  50% { 
    transform: translate(0,30%);
  }
`

const Visible  = styled.img<{animation?: any}>`
  animation-name: ${props => props.animation};
  animation-duration: ${props => {return props.animation == visibleAnimation? "2s": ".2s"}};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: ${props => {return props.animation == visibleAnimation? "infinite": "none"}};
  filter: contrast(10%);
`

export default AnimatedScroll
