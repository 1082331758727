import * as React from "react"
import { useTranslation } from "react-i18next";
import Hero from "../components/templates/hero";
import NavBar from "../components/organism/navBar";
import Footer from "../components/templates/footer";
import CompaniesBand from "../components/templates/companiesBand";
import { PhysicsContextProvider } from "../physics/physicsProvider";

// markup
const IndexPage = () => {
  const { t } = useTranslation();
    
  return (
    <div>
      <NavBar></NavBar>
      <PhysicsContextProvider>
        <Hero>
          <div className="flex flex-col">
          </div>
        </Hero>
      </PhysicsContextProvider>
      <CompaniesBand></CompaniesBand>
      <Footer/>
    </div>
  )
}

export default IndexPage
