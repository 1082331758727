import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import HeroTitle from "../atoms/heroTitle";

interface FooterProps {
  children?: JSX.Element[];
  className?: string;
}

const CompaniesBand = (props: FooterProps): JSX.Element => {
  const { t } = useTranslation();
  const WorkedCompanies = [
    {
      src: "companies/amo.svg",
      key: "amo",
      alt: "logo amo",
      width: "130rem",
    },
    {
      src: "companies/minilab.svg",
      key: "minilab",
      alt: "logo minilab",
      width: "60rem",
    },
    {
      src: "companies/multimagem.svg",
      key: "multimagem",
      alt: "logo multimagem",
      width: "150rem",
    },
    {
      src: "companies/praja.png",
      key: "praja",
      alt: "logo praja",
      width: "100rem",
    }
  ]
  return (
    <div className="flex flex-col gap-3 py-8">
      <h2 className="text-2xl text-bold ml-10 text-center">{t("Companies contracted me")}</h2>
      <div className="w-full flex justify-center items-center gap-10 py-5">
        {
          WorkedCompanies.map(e => {
            return (
              <div  className="cursor-pointer">
                <img width={e.width} src={e.src} alt={e.alt} key={e.key} />
              </div>
            )
          })
        }
      </div>

    </div>
  )
}



export default CompaniesBand;