import React, { useEffect } from "react"
import i18n from "../../i18n/i18n";
import ListSelect from "../atoms/listSelect";
import styled, {keyframes} from "styled-components";

const LanguageSelect = (): JSX.Element => {
  const [ a, setA ] = React.useState(i18n.language);
  const languages = [
    {
      src:"static/Portuguese.svg", 
      key:"pt",
      alt:"Brazillian Flag"
    },
    {
      src:"static/English.svg", 
      key:"en",
      alt:"USA flag"
    },
    {
      src:"static/French.svg", 
      key:"fr",
      alt:"French flag"
    }
  ]
  function compare_string_languages(a: string, b: string){
    return a.substring(0,2) == b.substring(0,2)
  }

  useEffect(() => {
    if(typeof window !== `undefined`){
      i18n.changeLanguage(navigator?.language)
    }
  }, [])
  
  return (

    <ListSelect className="flex" onSelect={e=> {setA(e); i18n.changeLanguage(e)}}>
      {
        languages.map( e=> {
          return compare_string_languages(a, e.key) ? 
          <Selected className="mx-2" src={e.src} alt={e.alt} key={e.key}/>: 
          <NotSelected className="mx-2" src={e.src} alt={e.alt} key={e.key}/>
        })
      }
      
    </ListSelect>
  )
}

const selectedAnimation = keyframes`
  100% { 
    transform: scale(1.2);
    filter: grayscale(0%) contrast(100%) brightness(100%) 
    }
`
const notSelectedAnimation = keyframes`
  100% { 
    transform: scale(1);
    filter: grayscale(100%) contrast(25%) brightness(150%) 
  }
`

const Selected = styled.img`
  min-width: 2rem;
  animation-name: ${selectedAnimation};
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  filter: grayscale(100%) contrast(25%) brightness(150%);
`
const NotSelected = styled.img`
  min-width: 2rem;
  box-shadow: 1rem;
  cursor: pointer;
  animation-name: ${notSelectedAnimation};
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  filter: grayscale(100%) contrast(25%) brightness(150%);
`

export default LanguageSelect